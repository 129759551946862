import { SearchIcon } from "@heroicons/react/outline";

function SearchBox({ setSearchbool, placeholder, search, setSearch }) {
  return (
    <div className="flex flex-col w-full items-end gap-3">
      <div className="flex flex-row items-center text-white px-2.5 h-10 border bg-white bg-opacity-10 border-primary-neutral-500 rounded-lg w-full">
        <SearchIcon className="h-4 mr-2.5" />
        <input
          placeholder={placeholder}
          value={search?.searchText}
          name="search"
          className="text-xs font-inter bg-transparent focus:ring-0 placeholder:text-primary-gray-250 w-11/12 focus:outline-none border-0"
          onChange={(e) => {
            e.target.value = e.target.value.trimStart();
            setSearch({
              ...search,
              searchText: e.target.value,
              isSearch: e.target.value !== "",
            });
            setSearchbool && setSearchbool(true);
          }}
        ></input>
      </div>
    </div>
  );
}

export default SearchBox;
