import React from "react";
import { Redirect } from "react-router-dom";
import { checkAppAccess } from "helpers/utils/accessCheck";
import Startup from "pages/Startup";
import IndividualPage from "pages/Startup/IndividualPage";
import Feedback from "pages/Startup/Feedback";

// Authenticated Paths

// Array of routes only a logged in user can access
const privateRoutes = [
  {
    path: "/startup/:id",
    component: IndividualPage,
    name: "Start Ups",
    tab: "Start Ups",
  },
  {
    path: "/startup",
    component: Startup,
    name: "Start Ups",
    tab: "Start Ups",
  },
  {
    path: "/feedback",
    component: Feedback,
    name: "Feedback",
    tab: "Feedback",
  },
  {
    path: "/startup/:id",
    component: Startup,
    name: "Start Ups",
    tab: "Start Ups",
  },

  {
    path: "/",
    exact: true,
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          window.innerWidth < 1024 ? (
            <Redirect to="/startup" />
          ) : (
            <Redirect to="/startup" />
          )
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <Redirect to="/auth/signIn" />
      ),
  },
];

export { privateRoutes };
