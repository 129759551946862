import ChronosButton from "components/Comman/Buttons";
import EmptyState from "components/Comman/EmptyState";
import FilterDropDown from "components/Comman/Inputs/FilterDropDown";
import SearchBox from "components/Comman/Inputs/SearchBox";
import { RadioTabs } from "components/Comman/Tabs";
import Card, { CardLoader } from "components/Startup/Card";
import StartupCreateEdit from "components/Startup/Modals/StartUpCreateEdit";
import { getUniqueValues, sortByKey } from "helpers/utils/array";
import { useEffect } from "react";
import { useRef } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { fetchAllStartups } from "redux/startup";

function Startup() {
  const dispatch = useDispatch();
  const history = useHistory();
  const list = useSelector((state) => state.startup?.list);
  const fetching = useSelector((state) => state.startup?.fetching);
  const currentPage = useSelector((state) => state.startup?.currentPage);
  const totalPages = useSelector((state) => state.startup?.totalPages);
  const [search, setSearch] = useState({ searchText: "" });
  const [addNew, setAddNew] = useState(false);
  const [selectedTab, setSelectedTab] = useState("incomplete");
  const [query, setQuery] = useState({ sort: "ascending" });
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  const getList = () => {
    let temp = [];

    switch (selectedTab) {
      case "incomplete":
        temp = list?.filter((i) => !i?.progressStats?.completed);
        break;
      case "completed":
        temp = list?.filter((i) => i?.progressStats?.completed);
        break;
      default:
        temp = list;
    }
    if (Object.keys(query)?.length > 0) {
      Object.keys(query)?.map((i) => {
        if (i !== "sort") temp = temp?.filter((item) => item[i] == query[i]);
      });
    }

    if (search?.searchText !== "")
      temp = temp?.filter((item) =>
        item?.name?.toLowerCase()?.includes(search?.searchText)
      );
    temp = sortByKey(temp, "name");
    if (query?.sort == "descending") {
      temp = temp.reverse();
    } else {
    }

    return temp;
  };

  useEffect(() => {
    console.log(isVisible);
    if (isVisible) {
      dispatch(fetchAllStartups({ page: currentPage + 1 }));
    }
  }, [isVisible]);

  return (
    <>
      <StartupCreateEdit
        isOpen={addNew}
        closeModal={() => {
          setAddNew(false);
        }}
      />
      <div className="px-2.5 w-full flex flex-col space-y-4 lg:space-y-8 max-w-7xl">
        <div className="py-4 w-full flex flex-col space-y-4 lg:space-y-8">
          <ChronosButton
            primary
            text="Add New Startup"
            onClick={() => {
              setAddNew(true);
            }}
          />
          <SearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Start typing to search..."
          />
          <div className="py-2 flex flex-row items-stretch max-w-full overflow-x-auto space-x-2">
            <FilterDropDown
              label="Sort By"
              values={[
                { label: "Ascending", value: "ascending" },
                { label: "Descending", value: "descending" },
              ]}
              query={query}
              setQuery={setQuery}
              field="sort"
            />

            <FilterDropDown
              label="Industry"
              values={getUniqueValues(list, "industry")?.map((i) => ({
                label: i,
                value: i,
              }))}
              query={query}
              setQuery={setQuery}
              field="industry"
            />

            <FilterDropDown
              label="Funding Status"
              values={getUniqueValues(list, "funding")?.map((i) => ({
                label: i,
                value: i,
              }))}
              query={query}
              setQuery={setQuery}
              field="funding"
            />
            <FilterDropDown
              label="Location"
              values={getUniqueValues(list, "workLocationType")?.map((i) => ({
                label: i,
                value: i,
              }))}
              query={query}
              setQuery={setQuery}
              field="workLocationType"
            />
          </div>
        </div>
        <div className="lg:px-4">
          <div className="max-w-full overflow-x-auto">
            <RadioTabs
              tabs={[
                { label: "Incomplete Research", value: "incomplete" },
                { label: "Research Completed", value: "completed" },
              ]}
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
            />
          </div>
          <div className="grid md:grid-cols-2 py-2.5 lg:py-6 lg:grid-cols-2 xl:grid-cols-3 gap-10 xl:gap-16">
            {getList()?.length > 0
              ? getList()?.map((item) => {
                  if (
                    search?.searchText == "" ||
                    (search?.searchText !== "" &&
                      item?.name?.toLowerCase()?.includes(search?.searchText))
                  )
                    return (
                      <>
                        <Card
                          item={item}
                          onClick={() => {
                            history.push(`/startup/${item?.id}`);
                          }}
                        />
                      </>
                    );
                })
              : !fetching && (
                  <div className="md:col-span-2 lg:col-span-3">
                    <EmptyState text="No Startup found!" />
                  </div>
                )}
            {fetching &&
              [1, 2, 3]?.map((item) => {
                return (
                  <div ref={ref} id="loader">
                    <CardLoader />
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Startup;

function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    [ref]
  );

  useEffect(() => {
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
}
