import Banner from "components/Startup/Individual/Banner";
import Details from "components/Startup/Individual/Details";
import Founders from "components/Startup/Individual/Founders";
import { getOneEntity } from "config/APIs/startup";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function IndividualPage() {
  const { id } = useParams();
  const [details, setDetails] = useState();

  const fetchDetails = async () => {
    try {
      let res = await getOneEntity(id);
      setDetails(res?.data?.data);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchDetails();
  }, []);

  return (
    <div className="px-2.5 w-full max-w-7xl py-10 flex flex-col space-y-20">
      <Banner details={details} />
      <Founders details={details} />
      <Details details={details} />
    </div>
  );
}

export default IndividualPage;
