// Just a normal grey dropdown for filtering

const FilterDropDown = ({ label, values, query, setQuery, field }) => {
  return (
    <div className="theme-input-dark border-0 text-white text-xs lg:text-base font-karla px-4 py-3 font-bold max-w-max">
      <select
        className="bg-transparent w-28 p-0 border-0 focus:outline-none focus:ring-0 text-xs lg:text-base"
        onChange={(e) => {
          setQuery({ ...query, [field]: e.target.value });
        }}
      >
        <option className="text-primary-neutral-500" value={label}>
          {label}
        </option>
        {/* <option className="text-primary-neutral-500" value={"All"}>
          All
        </option> */}
        {values.map((value) => (
          <option
            className="text-primary-neutral-500"
            id={value.value}
            key={value.value}
            value={value.value}
          >
            {value.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterDropDown;
