import { configureStore } from "@reduxjs/toolkit";

import toasterReducer from "./toaster";
import userReducer from "./user";
import startupReducer from "./startup";
const store = configureStore({
  reducer: {
    toaster: toasterReducer,
    user: userReducer,
    startup: startupReducer,
  },
});

export default store;
