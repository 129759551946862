import { updateEntity } from "config/APIs/startup";
import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchAllStartups } from "redux/startup";

function Card({ item, onClick }) {
  const dispatch = useDispatch();
  const [prioritized, setPrioritized] = useState(item?.prioritized);
  const handleUpdate = async (p) => {
    try {
      const response = await updateEntity(item?.id, { prioritized: p });

      if (response.status === 200) {
        let data = response.data.data;

        dispatch(fetchAllStartups());
      }
    } catch (err) {
      console.log("startup update error", err);
    }
  };

  useEffect(() => {
    setPrioritized(item?.prioritized);
  }, [item]);

  return (
    <div className="bg-white p-4 rounded-2xl flex flex-col space-y-4">
      <div className="flex flex-row items-center justify-between">
        <div
          onClick={() => {
            handleUpdate(!prioritized);
            setPrioritized(!prioritized);
          }}
          className="font-inter cursor-pointer hover:scale-105 ease-in-out duration-150 transition text-sm text-primary-neutral-500 font-semibold flex flex-row items-center space-x-2 p-2"
        >
          {prioritized ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M19.48 12.35C17.91 8.27 12.32 8.05 13.67 2.12C13.77 1.68 13.3 1.34 12.92 1.57C9.29 3.71 6.68 8 8.87 13.62C9.05 14.08 8.51 14.51 8.12 14.21C6.31 12.84 6.12 10.87 6.28 9.46C6.34 8.94 5.66 8.69 5.37 9.12C4.69 10.16 4 11.84 4 14.37C4.38 19.97 9.11 21.69 10.81 21.91C13.24 22.22 15.87 21.77 17.76 20.04C19.84 18.11 20.6 15.03 19.48 12.35ZM10.2 17.38C11.64 17.03 12.38 15.99 12.58 15.07C12.91 13.64 11.62 12.24 12.49 9.98C12.82 11.85 15.76 13.02 15.76 15.06C15.84 17.59 13.1 19.76 10.2 17.38Z"
                fill="url(#paint0_linear_19_755)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_19_755"
                  x1="11.9982"
                  y1="1.00774"
                  x2="11.9982"
                  y2="21.5126"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#FFBC00" />
                  <stop offset="1" stop-color="#F57D34" />
                </linearGradient>
              </defs>
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M19.98 12.35C18.41 8.26999 12.82 8.04999 14.17 2.11999C14.27 1.67999 13.8 1.33999 13.42 1.56999C9.79 3.70999 7.18 7.99999 9.37 13.62C9.55 14.08 9.01 14.51 8.62 14.21C6.81 12.84 6.62 10.87 6.78 9.45999C6.84 8.93999 6.16 8.68999 5.87 9.11999C5.19 10.16 4.5 11.84 4.5 14.37C4.88 19.97 9.61 21.69 11.31 21.91C13.74 22.22 16.37 21.77 18.26 20.04C20.34 18.11 21.1 15.03 19.98 12.35ZM10.7 17.38C12.14 17.03 12.88 15.99 13.08 15.07C13.41 13.64 12.12 12.24 12.99 9.97999C13.32 11.85 16.26 13.02 16.26 15.06C16.34 17.59 13.6 19.76 10.7 17.38Z"
                stroke="#696763"
              />
            </svg>
          )}
          <p>{prioritized ? "Prioritized" : "Prioritize"}</p>
        </div>
        <svg
          onClick={onClick}
          className="cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z"
            fill="#6775F5"
          />
        </svg>
      </div>
      <div className="w-full flex flex-row space-x-4 items-center">
        {/* <img
          src={item?.image?.url}
          alt=""
          className="w-16 h-16 rounded-lg object-cover"
        /> */}
        <div className="flex flex-col font-inter break-all">
          <h1 className="text-primary-neutral-800 text-xl font-semibold">
            {item?.name}
          </h1>
          <p className="text-primary-neutral-500 text-xs font-medium">
            {item?.industry}
          </p>
          {item?.linkedIn && (
            <div className="py-2 flex flex-row items-center space-x-2 text-primary-neutral-500 text-2xs font-light">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                <path
                  d="M14.5 1H2.49687C1.94687 1 1.5 1.45313 1.5 2.00938V13.9906C1.5 14.5469 1.94687 15 2.49687 15H14.5C15.05 15 15.5 14.5469 15.5 13.9906V2.00938C15.5 1.45313 15.05 1 14.5 1ZM5.73125 13H3.65625V6.31875H5.73438V13H5.73125ZM4.69375 5.40625C4.02812 5.40625 3.49063 4.86562 3.49063 4.20312C3.49063 3.54063 4.02812 3 4.69375 3C5.35625 3 5.89687 3.54063 5.89687 4.20312C5.89687 4.86875 5.35938 5.40625 4.69375 5.40625ZM13.5094 13H11.4344V9.75C11.4344 8.975 11.4187 7.97813 10.3562 7.97813C9.275 7.97813 9.10938 8.82188 9.10938 9.69375V13H7.03438V6.31875H9.025V7.23125H9.05312C9.33125 6.70625 10.0094 6.15312 11.0188 6.15312C13.1187 6.15312 13.5094 7.5375 13.5094 9.3375V13Z"
                  fill="#9C9A96"
                />
              </svg>
              <p className="line-clamp-1 break-before-all">{item?.linkedIn}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-full">
        <p className="text-primary-neutral-500 font-inter text-xs font-semibold">
          Research Completion
        </p>
        <div className="w-full flex flex-row items-center space-x-4">
          <div className="w-10/12 flex flex-row items-center rounded-full bg-primary-neutral-100 h-2">
            <div
              className="rounded-full h-2 bg-primary-indigo-300"
              style={{ width: "16%" }}
            />
          </div>
          <p className="text-primary-neutral-500 font-inter text-xs font-semibold">
            16%
          </p>
        </div>
      </div>
      <div className="w-full text-2xs text-primary-neutral-500 font-inter flex flex-row items-center justify-between">
        <div className="font-light">
          Created{" "}
          <span className="font-semibold">
            {moment(item?.createdAt).format("ll")}
          </span>
        </div>

        <div className="font-light">
          Research Status{" "}
          <span
            className={`font-semibold ${
              item?.progressStats?.completed
                ? "text-primary-green-500"
                : item?.researchId
                ? "text-primary-indigo-500"
                : ""
            }`}
          >
            {item?.progressStats?.completed
              ? "Completed"
              : item?.researchId
              ? "Active"
              : "Not Active"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Card;

function CardLoader() {
  return (
    <div className="bg-white p-4 rounded-2xl w-full flex flex-col space-y-4">
      <div className="flex flex-row items-center justify-between">
        <div className="font-inter cursor-pointer text-sm text-primary-neutral-500 font-semibold flex flex-row items-center space-x-2 p-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M19.98 12.35C18.41 8.26999 12.82 8.04999 14.17 2.11999C14.27 1.67999 13.8 1.33999 13.42 1.56999C9.79 3.70999 7.18 7.99999 9.37 13.62C9.55 14.08 9.01 14.51 8.62 14.21C6.81 12.84 6.62 10.87 6.78 9.45999C6.84 8.93999 6.16 8.68999 5.87 9.11999C5.19 10.16 4.5 11.84 4.5 14.37C4.88 19.97 9.61 21.69 11.31 21.91C13.74 22.22 16.37 21.77 18.26 20.04C20.34 18.11 21.1 15.03 19.98 12.35ZM10.7 17.38C12.14 17.03 12.88 15.99 13.08 15.07C13.41 13.64 12.12 12.24 12.99 9.97999C13.32 11.85 16.26 13.02 16.26 15.06C16.34 17.59 13.6 19.76 10.7 17.38Z"
              stroke="#696763"
            />
          </svg>
          <p>Prioritize</p>
        </div>
        <svg
          className="cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
        >
          <path
            d="M12.5 4L11.09 5.41L16.67 11H4.5V13H16.67L11.09 18.59L12.5 20L20.5 12L12.5 4Z"
            fill="#6775F5"
          />
        </svg>
      </div>
      <div className="w-full flex flex-row space-x-4 items-center">
        <div className="flex flex-col font-inter w-full space-y-2">
          <h1 className="h-4 rounded w-full bg-primary-neutral-200 animate-pulse text-primary-neutral-800 text-xl font-semibold"></h1>
          <p className="h-2 rounded w-6/12 bg-primary-neutral-200 animate-pulse text-primary-neutral-500 text-xs font-medium"></p>
        </div>
      </div>
      <div className="flex flex-col w-full">
        <p className="text-primary-neutral-500 font-inter text-xs font-semibold">
          Research Completion
        </p>
        <div className="w-full flex flex-row items-center space-x-4">
          <div className="w-10/12 flex flex-row items-center rounded-full bg-primary-neutral-100 h-2">
            <div
              className="rounded-full h-2 bg-primary-indigo-300"
              style={{ width: "16%" }}
            />
          </div>
          <p className="text-primary-neutral-500 font-inter text-xs font-semibold">
            16%
          </p>
        </div>
      </div>
      <div className="w-full text-2xs text-primary-neutral-500 font-inter flex flex-row items-center justify-between">
        <div className="font-light">
          Created <span className="font-semibold"></span>
        </div>

        <div className="font-light">Research Status Not Active</div>
      </div>
    </div>
  );
}

export { CardLoader };
