import { MagnifyingGlass, Layout, Cards } from "@phosphor-icons/react";

import StyleIcon from "@mui/icons-material/Style";

const mobileBottomNav = [
  {
    name: "Dashboard",
    path: "/dashboard",
    image: "/assets/svg/tabbar/dashboard.png",
    selectedImage: "/assets/svg/tabbar/selectedDashboard.png",
  },
  {
    name: "Stories",
    path: "/story",
    image: "/assets/svg/tabbar/story.png",
    selectedImage: "/assets/svg/tabbar/selectedStory.png",
  },
  {
    name: "Add",
    path: "/home/sus/0",
    image: "/assets/svg/tabbar/dashboard.png",
    selectedImage: "/assets/svg/tabbar/dashboard.png",
  },
  {
    name: "Draft",
    path: "/home/researchBank",
    image: "/assets/svg/tabbar/draft.png",
    selectedImage: "/assets/svg/tabbar/selectedDraft.png",
  },
  {
    name: "Bank",
    path: "/home/bank",
    image: "/assets/svg/tabbar/bank.png",
    selectedImage: "/assets/svg/tabbar/selectedBank.png",
  },
];
const laptopNav = [
  {
    name: "Dashboard",
    icon: <Layout size={20} />,
    subList: [
      { name: "Start Ups", path: "/startup" },
      { name: "Feedback", path: "/feedback" },
    ],
  },
];

export { mobileBottomNav, laptopNav };
