import React, { useState } from "react";
import { laptopNav } from "../../helpers/constants";
import LogoutModal from "./LogoutModal";
import { useHistory } from "react-router-dom";

function DesktopWrapper({ children }) {
  const history = useHistory();
  const [logOut, setLogOut] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Dashboard");

  const onLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("navList");
    localStorage.removeItem("selectedTab");
    window.location.href = window.location.origin + "/";
  };

  return (
    <>
      <LogoutModal
        onLogout={onLogout}
        isOpen={logOut}
        closeModal={() => {
          setLogOut(false);
        }}
      />

      <div className="flex-row items-stretch w-screen min-h-screen hidden lg:flex">
        <div className="fixed left-0 flex flex-row items-stretch h-[75vh] pt-28 pl-4">
          <div
            className={`z-20 lg:flex lg:flex-shrink-0 primary-sidebar-width `}
          >
            <div
              aria-label="Sidebar"
              className="py-6 w-full z-20 flex flex-col items-center space-y-1.5 rounded-full h-[75vh] bg-primary-neutral-800 overflow-y-auto h-80vh mb-5"
            >
              {laptopNav.map((item, index) => {
                if (item?.subList?.length > 0) {
                  return (
                    <>
                      <div
                        key={item.name}
                        onClick={() => {
                          setSelectedTab(item?.name);
                        }}
                        style={{
                          background:
                            item?.name === selectedTab ? "#312F36" : "",
                        }}
                        className={`flex flex-col items-center w-full space-y-2 relative px-2 pt-2 pb-2 ${
                          item?.name === selectedTab
                            ? "tab-selected"
                            : "border-0"
                        } border-primary-indigo-500 cursor-pointer transform transition duration-300 ease-in-out text-white`}
                      >
                        {item?.icon ? (
                          item?.icon
                        ) : (
                          <img
                            src={
                              item?.image
                              // item.title == selecteditem
                              //   ? item?.selectedImage
                              //   : item?.image
                            }
                            className={`${
                              item?.hideTitle
                                ? "h-12 w-12 transform scale-150"
                                : "h-6 w-6"
                            }`}
                          />
                        )}
                        <span
                          className={`text-white text-center font-lato font-normal text-2xs ${
                            item?.hideTitle && "hidden"
                          }`}
                        >
                          {item.name}
                        </span>
                      </div>
                      {item?.divider && (
                        <div className="py-1.5 w-full">
                          <div className="bg-primary-indigo-400 opacity-50 w-8/12 mx-auto my-0.5 h-1px" />
                        </div>
                      )}
                    </>
                  );
                }
              })}
            </div>
          </div>
          <nav
            aria-label="Sections"
            className="flex-shrink-0 z-0 lg:flex lg:flex-col secondary-nav-laptop -ml-14 pl-14 h-[75vh] secondary-sidebar-width py-10 pr-2.5 overflow-y-auto secondary-nav-laptop nav-bg"
          >
            {laptopNav?.find((item) => item?.name == selectedTab)?.subList &&
              laptopNav?.find((item) => item?.name == selectedTab)?.subList
                .length > 0 &&
              laptopNav
                ?.find((item) => item?.name == selectedTab)
                ?.subList.map((item2) => {
                  return (
                    <>
                      <div className="py-0.5 relative pr-1 pl-2">
                        <div
                          key={item2.name}
                          onClick={() => {
                            history.push(item2?.path);
                          }}
                          className={`${
                            window.location.pathname.includes(item2.path)
                              ? "nav-selected"
                              : ""
                          } flex px-3 py-2 cursor-pointer relative text-white font-bold secondaryNavItem`}
                          aria-current={item2.current ? "page" : undefined}
                        >
                          <p className="font-lato text-sm transform transition  hover:font-bold">
                            {item2.name}
                          </p>
                        </div>
                      </div>
                      {item2?.endDivider == true && (
                        <div className="bg-primary-indigo-300 opacity-30 w-full mx-auto my-1.5 h-1px" />
                      )}
                    </>
                  );
                })}
          </nav>
        </div>

        <div className="flex flex-col items-stretch w-full lg:ml-[310px] lg:mt-[100px] lg:pr-5">
          {children}
        </div>
      </div>
    </>
  );
}

export default DesktopWrapper;
