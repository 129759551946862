import moment from "moment";
import React from "react";

function Details({ details }) {
  return (
    <div className="w-full flex flex-col space-y-6">
      <h1 className="text-white font-poppins text-xl">All Details</h1>
      <Table
        heading="General Information"
        list={[
          { label: "Company Name", value: details?.name },
          { label: "Industry", value: details?.industry },
          { label: "Description", value: details?.description },
          {
            label: "Founding Date",
            value: details?.foundingDate
              ? moment(details?.foundingDate).format("ll")
              : null,
          },
          { label: "Location", value: details?.workLocationType },
        ]}
      />
      <Table
        heading="Company Motto"
        list={[
          { label: "Mission", value: details?.mission },
          { label: "Vision", value: details?.vision },
          {
            label: "Values",
            value:
              details?.values && details?.values?.length > 0
                ? details?.values?.join(",")
                : null,
          },
        ]}
      />
      <Table
        heading="Operational Details"
        list={[
          { label: "Company Age", value: details?.ageOfCompany },
          {
            label: "Company Size",
            value: details?.numOfEmployees?.value
              ? details?.numOfEmployees?.value
              : null,
          },
          {
            label: "Funding Status",
            value: details?.funding,
          },
          {
            label: "Total Revenue",
            value: details?.currentRevenue,
          },
        ]}
      />
      <Table
        heading="Digital Footprint"
        list={[
          { label: "Company Website", value: details?.website },
          { label: "Company Instagram", value: details?.instagram },
          {
            label: "Instagram Followers",
            value: details?.instagramFollowers || null,
          },
          {
            label: "Company Linkedin",
            value: details?.linkedIn,
          },

          { label: "LinkedIn Followers", value: details?.linkedIn || null },
        ]}
      />
      <Table
        heading="Founder Details"
        list={[
          { label: "No. of Founders", value: details?.founders?.length || 0 },
          {
            label: "Founder Names",
            value:
              details?.founders?.length > 0
                ? details?.founders?.map((i) => i?.name)?.join(", ")
                : null,
          },
          {
            label: "Founder Ages",
            value:
              details?.founders?.length > 0
                ? details?.founders?.map((i) => i?.age?.value)?.join(", ")
                : null,
          },
        ]}
      />
    </div>
  );
}

export default Details;

function Table({ list, heading }) {
  return (
    <div className="flow-root">
      <div className="overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <table className="min-w-full">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="py-6 px-2 text-left text-lg font-medium text-primary-indigo-300"
                >
                  {heading}{" "}
                </th>
              </tr>
            </thead>
            <tbody className="">
              {list.map((item) => (
                <tr
                  className={`even:bg-opacity-[0.08] bg-opacity-[0.05] bg-white text-base font-inter ${
                    item?.value ? "" : "border-l-4 border-primary-indigo-300"
                  }`}
                >
                  <td className="whitespace-nowrap text-primary-neutral-300 font-medium py-4 px-6 tracking-[0.8px]">
                    {item?.label}
                  </td>
                  <td className="whitespace-nowrap text-white text-center font-medium py-4 px-6 tracking-[0.32px]">
                    {item?.value ? item?.value : <i>Missing</i>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
