import { ArrowRight } from "@phosphor-icons/react";
import ChronosButton from "../Buttons";

export default function EmptyState({ image, ctaText, ctaLink, text, subText }) {
  return (
    <div
      className={`rounded-lg bg-white bg-opacity-10 p-4 space-y-4 min-h-[25vh] flex flex-col items-center justify-center`}
    >
      {image && <img src={image} alt="" className="w-20 h-20 object-contain" />}
      <h1 className="text-white font-inter text-sm font-semibold leading-5">
        {text}
      </h1>
      <h1 className="text-white font-inter text-xs font-light leading-5">
        {subText}
      </h1>
      {ctaText && (
        <ChronosButton
          text={ctaText}
          onClick={() => {}}
          tertiary
          underline
          icon={<ArrowRight size={20} />}
        />
      )}
    </div>
  );
}
