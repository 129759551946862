import * as StartupAPI from "config/APIs/startup";
import { useState } from "react";
import { Drawer } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { showToast } from "redux/toaster";
import { fetchAllStartups } from "redux/startup";
import SimpleInput from "components/Comman/Inputs/SimpleInput";
import ChronosButton from "components/Comman/Buttons";
import { FilePicker } from "react-file-picker";
import { deleteFile, uploadFile } from "config/APIs/files";

function StartupCreateEdit({ isOpen, closeModal, editValues }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message, type: "success" }));
  };
  const handleUpdate = async () => {};
  const handleCreate = async () => {
    setLoader(true);

    try {
      const response = await StartupAPI.createEntity(data);
      const newProject = response.data.data?.project;

      dispatch(fetchAllStartups());

      showSuccessNotification("Startup Created Successfully!");

      closeModal();
      setData({});
    } catch (err) {
      console.log("Error", err);
      switch (err.response?.status) {
        case 422:

        case 401:
          console.log(err?.response);
        default:
          showErrorNotification(err.response?.data?.message);
      }
    }

    setLoader(false);
  };

  useEffect(() => {
    if (editValues) {
      setData(editValues);
    }
  }, [editValues]);

  const handleFilePicker = async (pickedFile) => {
    if (pickedFile.size > 10485760) {
      handleError("File size cannot exceed 10MB");
      return;
    }
    console.log(pickedFile);

    try {
      const response = await uploadFile({ file: pickedFile });

      const { file } = response.data.data;
      console.log(file);
      setData({ ...data, image: file, imageId: file?.id });
    } catch (err) {
      console.log("File Upload error", err);
      const message = `File Upload Error!`;
      handleError(message);
    }
  };

  const handleDelete = async () => {
    await deleteFile({ id: data?.imageId });
    setData({ ...data, image: null, imageId: null });
  };
  const handleError = (err) => {
    dispatch(showToast({ message: err, type: "error" }));
  };

  const handleFilePickerError = (err) => {
    console.log("File Picker Error", err);
    const message = `Please select an appropriate image file!`;
    handleError(message);
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          borderRadius: window.innerWidth < 1024 ? "20px 20px 0px 0px" : "0px",
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100%" : "560px",
          background: "transparent",
        },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
        setData({});
      }}
      transitionDuration={250}
    >
      <div className="h-full overflow-y-auto z-50 bg-white px-8 py-6">
        <div className="mt-4">
          <div className="flex flex-row items-center space-x-2">
            <svg
              className="cursor-pointer"
              onClick={closeModal}
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
            >
              <path
                d="M12.7116 5.91125L7.47645 11.1464L6.62289 12H7.83H19.5V13H7.83H6.62442L7.47613 13.8532L12.7032 19.0897L12 19.7929L4.70711 12.5L12.0012 5.20586L12.7116 5.91125Z"
                fill="#696763"
                stroke="#696763"
              />
            </svg>{" "}
            <h2 className="text-2xl font-semibold font-lato">
              {editValues ? "Update " : "Add New "} Startup{" "}
            </h2>
          </div>
          <div className="flex flex-col gap-8 mt-5 lg:mt-16">
            <SimpleInput
              label="Startup Name"
              details={data}
              setDetails={setData}
              field="name"
            />
            <div className="flex flex-col space-y-3">
              <div className="input-label">Startup Logo</div>
              <div className="flex flex-row items-center space-x-4">
                {data?.image?.url ? (
                  <img
                    src={data?.image?.url}
                    alt=""
                    className="w-[100px] h-[100px] "
                  />
                ) : (
                  <div className="w-[100px] h-[100px] rounded-lg shadow-inner bg-primary-neutral-50 flex flex-row items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="32"
                      height="32"
                      viewBox="0 0 32 32"
                      fill="none"
                    >
                      <path
                        d="M25.3333 9.33335V13.32C25.3333 13.32 22.68 13.3334 22.6667 13.32V9.33335H18.6667C18.6667 9.33335 18.68 6.68002 18.6667 6.66669H22.6667V2.66669H25.3333V6.66669H29.3333V9.33335H25.3333ZM21.3333 14.6667V10.6667H17.3333V6.66669H6.66667C5.2 6.66669 4 7.86669 4 9.33335V25.3334C4 26.8 5.2 28 6.66667 28H22.6667C24.1333 28 25.3333 26.8 25.3333 25.3334V14.6667H21.3333ZM6.66667 25.3334L10.6667 20L13.3333 24L17.3333 18.6667L22.6667 25.3334H6.66667Z"
                        fill="#CFCDC9"
                      />
                    </svg>
                  </div>
                )}
                {data?.image?.url ? (
                  <p
                    onClick={() => {
                      handleDelete();
                    }}
                    className="font-inter text-primary-indigo-500 text-sm font-semibold underline underline-offset-2 cursor-pointer"
                  >
                    Remove Logo
                  </p>
                ) : (
                  <FilePicker
                    extensions={["png", "jpeg", "jpg", "svg"]}
                    onChange={handleFilePicker}
                    onError={handleFilePickerError}
                    maxSize={6000}
                  >
                    <div className="space-x-2 flex flex-row items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M11 14.9861C11 15.5384 11.4477 15.9861 12 15.9861C12.5523 15.9861 13 15.5384 13 14.9861V7.82831L16.2428 11.0711L17.657 9.65685L12.0001 4L6.34326 9.65685L7.75748 11.0711L11 7.82854V14.9861Z"
                          fill="#6775F5"
                        />
                        <path
                          d="M4 14H6V18H18V14H20V18C20 19.1046 19.1046 20 18 20H6C4.89543 20 4 19.1046 4 18V14Z"
                          fill="#6775F5"
                        />
                      </svg>{" "}
                      <p className="font-inter text-primary-indigo-500 text-sm font-semibold underline underline-offset-2 cursor-pointer">
                        Upload an Image
                      </p>
                    </div>
                  </FilePicker>
                )}
              </div>
            </div>
            <SimpleInput
              label="Startup Industry"
              details={data}
              setDetails={setData}
              field="industry"
            />{" "}
            <SimpleInput
              label="Startup Linkedin"
              details={data}
              setDetails={setData}
              field="linkedIn"
            />
            {editValues ? (
              <div className="flex justify-between items-center gap-6">
                <ChronosButton
                  text="Delete"
                  tertiary
                  red
                  onClick={() => {
                    // handleDelete();
                  }}
                />{" "}
                <button className="p-3 px-4 text-caarya-red-dark  font-inter text-sm ">
                  Delete
                </button>
                <ChronosButton
                  text="Save"
                  primary
                  onClick={() => {
                    handleUpdate();
                  }}
                />
              </div>
            ) : (
              <div className="flex flex-row justify-center space-x-3 items-center py-6 ">
                <ChronosButton
                  text="Cancel"
                  secondary
                  onClick={() => {
                    closeModal();
                  }}
                />{" "}
                <ChronosButton
                  loader={loader}
                  text="Add Now"
                  primary
                  onClick={() => {
                    handleCreate();
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default StartupCreateEdit;
