import EmptyState from "components/Comman/EmptyState";
import FilterDropDown from "components/Comman/Inputs/FilterDropDown";
import SearchBox from "components/Comman/Inputs/SearchBox";
import Card, { CardLoader } from "components/Startup/Card";
import { getUniqueValues, sortByKey } from "helpers/utils/array";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

function Feedback() {
  const history = useHistory();
  const list = useSelector((state) => state.startup?.list);
  const fetching = useSelector((state) => state.startup?.fetching);
  const [search, setSearch] = useState({ searchText: "" });
  const [query, setQuery] = useState({ sort: "ascending" });
  const getList = () => {
    let temp = list?.filter((a) => a?.metaData !== null);

    if (Object.keys(query)?.length > 0) {
      Object.keys(query)?.map((i) => {
        if (i !== "sort") temp = temp?.filter((item) => item[i] == query[i]);
      });
    }

    if (search?.searchText !== "")
      temp = temp?.filter((item) =>
        item?.name?.toLowerCase()?.includes(search?.searchText)
      );
    temp = sortByKey(temp, "name");
    if (query?.sort == "descending") {
      temp = temp.reverse();
    } else {
    }

    return temp;
  };
  return (
    <>
      <div className="px-2.5 w-full flex flex-col space-y-4 lg:space-y-8 max-w-7xl">
        <div className="py-4 w-full flex flex-col space-y-4 lg:space-y-8">
          <SearchBox
            search={search}
            setSearch={setSearch}
            placeholder="Start typing to search..."
          />
          <div className="py-2 flex flex-row items-stretch max-w-full overflow-x-auto space-x-2">
            <FilterDropDown
              label="Sort By"
              values={[
                { label: "Ascending", value: "ascending" },
                { label: "Descending", value: "descending" },
              ]}
              query={query}
              setQuery={setQuery}
              field="sort"
            />

            <FilterDropDown
              label="Industry"
              values={getUniqueValues(list, "industry")?.map((i) => ({
                label: i,
                value: i,
              }))}
              query={query}
              setQuery={setQuery}
              field="industry"
            />

            <FilterDropDown
              label="Funding Status"
              values={getUniqueValues(list, "funding")?.map((i) => ({
                label: i,
                value: i,
              }))}
              query={query}
              setQuery={setQuery}
              field="funding"
            />
            <FilterDropDown
              label="Location"
              values={getUniqueValues(list, "workLocationType")?.map((i) => ({
                label: i,
                value: i,
              }))}
              query={query}
              setQuery={setQuery}
              field="workLocationType"
            />
          </div>
        </div>
        <div className="lg:px-4">
          <div className="grid md:grid-cols-2 py-2.5 lg:py-6 lg:grid-cols-2 xl:grid-cols-3 gap-10 xl:gap-16">
            {fetching ? (
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]?.map((item) => {
                return (
                  <>
                    <CardLoader />
                  </>
                );
              })
            ) : getList()?.length > 0 ? (
              getList()?.map((item) => {
                if (
                  search?.searchText == "" ||
                  (search?.searchText !== "" &&
                    item?.name?.toLowerCase()?.includes(search?.searchText))
                )
                  return (
                    <>
                      <Card
                        item={item}
                        onClick={() => {
                          history.push(`/startup/${item?.id}`);
                        }}
                      />
                    </>
                  );
              })
            ) : (
              <div className="md:col-span-2 lg:col-span-3">
                <EmptyState text="No Startup found!" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Feedback;
