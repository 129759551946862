function getUniqueValues(array, key) {
  const uniqueValues = [];
  const seenValues = new Set();

  array.forEach((obj) => {
    const value = obj[key];
    if (!seenValues.has(value)) {
      seenValues.add(value);
      uniqueValues.push(value);
    }
  });

  return uniqueValues;
}

function sortByKey(array, key) {
  return array.sort((a, b) => {
    const valueA = a[key];
    const valueB = b[key];

    if (valueA < valueB) {
      return -1;
    } else if (valueA > valueB) {
      return 1;
    } else {
      return 0; // values are equal
    }
  });
}

export { getUniqueValues, sortByKey };
