import { createSlice } from "@reduxjs/toolkit";
import { getAllEntity } from "config/APIs/startup";

const initialState = {
  list: [],
  currentPage: 0,
  totalPages: 0,
  fetching: true,
};

// Storing User details and Token
export const startups = createSlice({
  name: "startup",
  initialState,
  reducers: {
    updateList: (state, action) => {
      if (action.payload.data) {
        let d = action.payload.data;
        let list = d?.response;
        state.list = [...state.list].concat(list);
        state.currentPage = d?.currentPage;
        state.totalPages = d?.totalPages;
      }
      if (state.currentPage >= state.totalPages) state.fetching = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = startups.actions;

export default startups.reducer;

export function fetchAllStartups({ page = 0 }) {
  return async (dispatch) => {
    try {
      const response = await getAllEntity({ page: page, size: 12 });

      if (response.status === 200) {
        let data = response.data.data;

        dispatch(
          updateList({
            data: data,
          })
        );
      }
    } catch (err) {
      console.log("project fetching error", err);
    }
  };
}
